<script setup lang="ts">
// Copied this component over from Tairo Demo components
const props = withDefaults(
  defineProps<{
    /**
     * Determines whether the component should be in an expanded state
     * e.g., if used in a collapsible menu, `true` might mean the menu is open,
     * `false` means it's collapsed
     */
    expanded?: boolean

    /**
     * The current value for the component, expected to be a date
     * Can either be a JavaScript Date object or a string representation of a date
     */
    modelValue?: string | string[] | Date | Date[]

    /**
     * If set to `true`, the current date will be used as the default value for the component
     * Useful to highlight today's date by default
     */
    showTodayAsDefaultDate?: boolean

    /**
     * If set to `true`, then you can select multiple dates
     * and the modelValue would be an array of date strings
     */
    multipleDates?: boolean

    /**
     * Determines whether date selection is disabled or not.
     */
    disabled?: boolean

    /**
     * Array of dates that are selectable. If provided, only these dates can be selected.
     * Dates can be provided as Date objects or date strings in YYYY-MM-DD format
     */
    selectableDates?: (Date | string)[]
  }>(),
  {
    expanded: false,
    modelValue: undefined,
    showTodayAsDefaultDate: false,
    multipleDates: false,
    disabled: false,
    selectableDates: undefined,
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', date: string | string[]): void
}>()

const { d: $d } = useI18n({ useScope: 'global' })

function localizeMonthName(month: number, format: 'long' | 'short' = 'long') {
  if (month === undefined) return ''
  const referenceDate = new Date(2024, month)
  return capitalize($d(referenceDate, { month: format }))
}

// Localized version of the days of the week with Monday as the first day
function localizeWeekDay(weekdayNumber: number) {
  const referenceDate = new Date(2024, 0, weekdayNumber)
  return capitalize($d(referenceDate, { weekday: 'short' }))
}

// Array of localized weekdays with Monday as the first day
const weekdays = computed(() => {
  return Array.from({ length: 7 }, (_, i) => localizeWeekDay((i + 1) % 7)) // Shifts weekday index to start from Monday
})

enum DateFormat {
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'DD-MM-YYYY' = 'DD-MM-YYYY',
  'D d M, Y' = 'D d M, Y',
}

const showDatepicker = ref(false)
const datepickerValue = ref('')

let initialSelectedDatesValue: string | string[] = []
if (props.modelValue) {
  if (Array.isArray(props.modelValue)) {
    if (!props.multipleDates) {
      console.error('modelValue is an array but multipleDates is not set to true')
    }
    initialSelectedDatesValue = props.modelValue.map(date =>
      formatDateForComparison(new Date(date)),
    )
  }
  else if (props.modelValue instanceof Date) {
    initialSelectedDatesValue = formatDateForComparison(new Date(props.modelValue))
  }
  else if (typeof props.modelValue === 'string') {
    initialSelectedDatesValue = formatDateForComparison(new Date(props.modelValue))
  }
  else {
    console.error('Unexpected modelValue type:', typeof props.modelValue)
    initialSelectedDatesValue = formatDateForComparison(props.modelValue)
  }
}

if (props.multipleDates && typeof initialSelectedDatesValue === 'string') {
  initialSelectedDatesValue = [initialSelectedDatesValue]
}

const selectedDates = ref(initialSelectedDatesValue)
const dateFormat = ref(DateFormat['YYYY-MM-DD'])
const month = ref()
const year = ref()
const numberOfDays = ref<number[]>([])
const blankDays = ref<number[]>([])

function initDate() {
  let initialDate
  if (typeof selectedDates.value === 'string') {
    initialDate = new Date(Date.parse(selectedDates.value))
  }
  else if (selectedDates.value.length) {
    initialDate = new Date(Date.parse(selectedDates.value[0]))
  }
  else {
    initialDate = new Date()
  }
  month.value = initialDate.getMonth()
  year.value = initialDate.getFullYear()
}

function isSelectableDate(date: number) {
  if (!props.selectableDates) return true
  
  const dateToCheck = new Date(year.value, month.value, date)
  const formattedDate = formatDateForComparison(dateToCheck)
  
  return props.selectableDates.some(selectableDate => {
    if (selectableDate instanceof Date) {
      return formatDateForComparison(selectableDate) === formattedDate
    }
    return selectableDate === formattedDate
  })
}

function formatDateForDisplay(date: Date) {
  let formattedDay = localizeWeekDay(date.getDay())
  let formattedDate = ('0' + date.getDate()).slice(-2) // appends 0 (zero) in single digit date
  let formattedMonth = localizeMonthName(date.getMonth())
  let formattedMonthShortName = localizeMonthName(date.getMonth(), 'short')
  let formattedMonthInNumber = (
    '0'
    + (parseInt(date.getMonth().toString()) + 1)
  ).slice(-2)
  let formattedYear = date.getFullYear()
  if (dateFormat.value === DateFormat['DD-MM-YYYY']) {
    return `${formattedDate}-${formattedMonthInNumber}-${formattedYear}` // 02-04-2022
  }
  if (dateFormat.value === DateFormat['YYYY-MM-DD']) {
    return `${formattedYear}-${formattedMonthInNumber}-${formattedDate}` // 2022-04-02
  }
  if (dateFormat.value === DateFormat['D d M, Y']) {
    return `${formattedDay} ${formattedDate} ${formattedMonthShortName} ${formattedYear}` // Tue 02 Mar 2022
  }
  return `${formattedDay} ${formattedDate} ${formattedMonth} ${formattedYear}`
}

function formatDateForComparison(date: Date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

function isSelectedDate(date: number) {
  const d = new Date(year.value, month.value, date)
  return selectedDates.value.includes(formatDateForComparison(d))
}

function isToday(date: number) {
  const today = new Date()
  const d = new Date(year.value, month.value, date)
  return today.toDateString() === d.toDateString() ? true : false
}

function getDateValue(date: number) {
  if (!isSelectableDate(date)) return
  const selectedDate = new Date(year.value, month.value, date)
  const formattedDate = formatDateForDisplay(selectedDate)
  if (props.multipleDates) {
    toggleDateInMultipleSelection(formattedDate)
  }
  else {
    setSelectedDate(formattedDate)
  }
  emit('update:modelValue', selectedDates.value)
}

function toggleDateInMultipleSelection(formattedDate: string) {
  const dateIndex = selectedDates.value.indexOf(formattedDate)
  if (selectedDates.value instanceof Array) {
    if (dateIndex !== -1) {
      selectedDates.value.splice(dateIndex, 1)
    }
    else {
      selectedDates.value.push(formattedDate)
    }
  }
}
function setSelectedDate(formattedDate: string) {
  selectedDates.value = formattedDate
  showDatepicker.value = false
}

function getNoOfDays() {
  let daysInMonth = new Date(year.value, month.value + 1, 0).getDate()

  // Calculate the starting day of the week for the month
  let dayOfWeek = new Date(year.value, month.value).getDay() // 0 for Sunday, 6 for Saturday

  // Shift to make Monday the first day
  dayOfWeek = (dayOfWeek + 6) % 7 // Shift Sunday (0) to be last (6)

  let blankdaysArray = []
  for (let i = 1; i <= dayOfWeek; i++) {
    blankdaysArray.push(i)
  }

  let daysArray = []
  for (let d = 1; d <= daysInMonth; d++) {
    daysArray.push(d)
  }

  blankDays.value = blankdaysArray
  numberOfDays.value = daysArray
}

function incrementDays() {
  if (month.value == 0) {
    year.value--
    month.value = 12
  }
  else {
    month.value--
  }
  getNoOfDays()
}

function decrementDays() {
  if (month.value == 11) {
    month.value = 0
    year.value++
  }
  else {
    month.value++
  }
  getNoOfDays()
}

onMounted(() => {
  initDate()
  getNoOfDays()
})
</script>

<template>
  <div
    class="relative w-full rounded-md p-2 pb-5 font-sans shadow shadow-gray-300"
    :class="props.expanded ? 'max-w-[310px]' : 'max-w-[240px]'"
  >
    <input
      type="hidden"
      name="date"
      :value="datepickerValue"
    >
    <input
      v-model="datepickerValue"
      type="text"
      class="text-muted-600 hidden w-full rounded-lg py-3 pe-10 ps-4 font-medium leading-none shadow-sm focus:outline-none focus:ring focus:ring-blue-600/50"
      placeholder="Select date"
      readonly
      @click.prevent="showDatepicker = !showDatepicker"
    >

    <div class="w-full">
      <div class="mb-6 flex items-center justify-between">
        <div
          class="text-muted-800 dark:text-muted-100 space-x-2 text-base font-medium"
        >
          <span>{{ localizeMonthName(month) }}</span>
          <span>{{ year }}</span>
        </div>
        <div>
          <button
            type="button"
            class="focus:shadow-outline inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out focus:outline-none"
            :class="disabled ? 'cursor-not-allowed' : 'hover:bg-muted-100'"
            :disabled="disabled"
            @click.prevent="incrementDays"
          >
            <svg
              class="text-muted-400 inline-flex size-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            type="button"
            class="focus:shadow-outline inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out focus:outline-none"
            :class="disabled ? 'cursor-not-allowed' : 'hover:bg-muted-100'"
            :disabled="disabled"
            @click.prevent="decrementDays"
          >
            <svg
              class="text-muted-400 inline-flex size-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="-mx-1 mb-3 flex flex-wrap">
        <template v-for="(day, index) in weekdays" :key="index">
          <div style="width: 14.26%" class="px-0.5">
            <div
              class="text-muted-400 dark:text-muted-300 text-center text-xs font-medium"
            >
              {{ day }}
            </div>
          </div>
        </template>
      </div>

      <div class="-mx-1 flex flex-wrap">
        <template v-for="blankday in blankDays" :key="blankday">
          <div
            style="width: 14.28%"
            class="border border-transparent p-1 text-center text-sm"
          />
        </template>
        <template v-for="(date, dateIndex) in numberOfDays" :key="dateIndex">
          <div style="width: 14.28%" class="flex items-center justify-center">
            <a
              href="#"
              class="mx-auto flex size-8 cursor-pointer items-center justify-center rounded-full text-center text-sm leading-none transition duration-100 ease-in-out"
              :class="[
                disabled || !isSelectableDate(date) ? 'cursor-not-allowed opacity-50' : '',
                showTodayAsDefaultDate && isToday(date) === true
                  ? 'bg-primary-100 dark:bg-primary-500/20 text-primary-500'
                  : '',
                isToday(date) === false && isSelectedDate(date) === false && isSelectableDate(date)
                  ? 'text-muted-500 dark:text-muted-400 hover:text-primary-500 hover:bg-primary-100 dark:hover:bg-primary-500/20'
                  : '',
                !isSelectableDate(date)
                  ? 'text-muted-300 dark:text-muted-600'
                  : '',
                isSelectedDate(date) === true
                  ? 'bg-primary-500 hover:bg-primary-500/75 text-white'
                  : '',
              ]"
              @click.prevent="!disabled && isSelectableDate(date) && getDateValue(date)"
            >
              {{ date }}
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
